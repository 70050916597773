import { render, staticRenderFns } from "./TsmPride.vue?vue&type=template&id=2f4e9077&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "691caf1a"
  
)

export default component.exports