import { render, staticRenderFns } from "./Timer.vue?vue&type=template&id=f1d94fce&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "47d4c85c"
  
)

export default component.exports