import { render, staticRenderFns } from "./ChevronRight.vue?vue&type=template&id=d33e8c8c&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "3b62e4da"
  
)

export default component.exports