import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=310f0a66&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "30be2b98"
  
)

export default component.exports