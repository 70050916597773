import { render, staticRenderFns } from "./Covid19Secure.vue?vue&type=template&id=7aac980e&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "5ac64d0a"
  
)

export default component.exports