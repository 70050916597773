<template>
    <component 
        :is="iconComponent" 
        :width="width"
        :height="height"
        :viewBox="viewBox"
        :aria-hidden="ariaHidden"
        class="fill-current"
        />
</template>

<script>

const icons = {}

const requireComponents = require.context('./icons/', false, /[\w]\.vue$/)

requireComponents.keys().forEach(fileName => {
    const iconName = fileName.replace(/^\.\/(.+)\.vue/, '$1')
    const componentConfig = requireComponents(fileName)
    icons[iconName] = componentConfig.default || componentConfig
})

export default {
    props: {
        name: {
            type: String, 
            required: true,
            validator(value) {
                return Object.prototype.hasOwnProperty.call(icons, value)
            }
        },
        viewBox: {
            type: String, 
            default: '0 0 24 24'
        },
        width: {
            type: [Number, String], 
            default: 24 
        },
        height: {
            type: [Number, String], 
            default: 24 
        },
        ariaHidden: {
            type: Boolean, 
            default: true
        }
    },
    computed: {
        iconComponent() {
            return icons[this.name]
        }
    }
}
</script>