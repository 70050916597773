import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=7a5bb8f5&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  "52da5f78"
  
)

export default component.exports